import React from 'react';
import styled from 'styled-components';
import { navigate, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { media } from '../../utils/MediaResponsive';
import BackgroundImg from '../../images/letters-bgi.png';
import RectangularButton from '../Button/RectangularButton';
import Spacer from '../Spacer';
import jsonData from '../../content/vocab-builder/learn';

const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 70px;
  text-align: center;
  color: ${p => p.theme.bgHeader};

  ${media.tablet`
    font-size: 24px;
    line-height: 40px;
  `}
`;

const Button = styled(RectangularButton)`
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.0696048);
  border-radius: 4px;
  padding: 1em 4em;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.466667px;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-width: 40%;
`;

const Description = styled.p``;

const ImageWrapper = styled.div`
  width: 16em;
  position: absolute;

  &.img-0 {
    top: -10%;
    left: 30%;
  }

  &.img-1 {
    bottom: 0;
    left: 0;
  }

  &.img-2 {
    top: 0;
    right: 0;
  }

  &.img-3 {
    left: 45%;
    top: 42%;
  }

  &.img-4 {
    bottom: 20%;
    left: 20%;
  }

  &.img-5 {
    left: -8%;
    top: 5%;
  }

  &:hover {
    z-index: 1;
  }
`;

const Images = styled.div`
  position: relative;
  flex: 1;
  height: 20em;
  max-width: 35em;
`;

const Wrapper = styled.div`
  background: url(${BackgroundImg}) center center no-repeat;
  background-size: contain;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 920px) {
    flex-flow: column nowrap;
    font-size: 0.8rem;

    ${ContentContainer} {
      max-width: 60vw;
    }

    ${Images} {
      width: 90vw;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 768px) {
    ${ContentContainer} {
      max-width: 90vw;
    }
  }
`;

const Learn = () => (
  <StaticQuery
    query={graphql`
      query {
        imgs: allFile(filter: { relativeDirectory: { eq: "vocab-builder/learn" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 332) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <ContentContainer>
          <Spacer tablet={100} desktop={200} />
          <Title>{jsonData.title}</Title>
          <Description>{jsonData.description}</Description>
          <Spacer height={31} />
          <Button onClick={() => navigate(jsonData.leanNow.href)}>{jsonData.leanNow.text}</Button>
          <Spacer tablet={100} desktop={200} />
        </ContentContainer>
        <Images>
          {data.imgs.edges.map((edge, i) => (
            <ImageWrapper key={edge.node.childImageSharp.fluid.originalName} className={`img-${i}`}>
              <Img fluid={edge.node.childImageSharp.fluid} />
            </ImageWrapper>
          ))}
        </Images>
      </Wrapper>
    )}
  />
);

export default Learn;
