import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabs.css';

function CustomizedTabs({ tabs }) {
  return (
    <Tabs>
      <TabList>
        {tabs.map(tab => (
          <Tab key={tab.name.replace(' ', '').trim()}>{tab.name}</Tab>
        ))}
      </TabList>

      {tabs.map((tab, index) => (
        <TabPanel key={index.toString()}>
          <ul>
            {tab.contents.map((content, subIndex) => (
              <li key={subIndex.toString()}>{content}</li>
            ))}
          </ul>
        </TabPanel>
      ))}
    </Tabs>
  );
}

CustomizedTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default CustomizedTabs;
