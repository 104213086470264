import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const StepIcon = styled(Icon)`
  font-size: 4em;
  margin-right: 0.3em;
`;

const Title = styled.h1`
  font-size: 1em;
  line-height: 1.5em;
  color: ${p => p.theme.bgHeader};
  margin: 0;
`;

const Description = styled.p`
  margin: 0.5em 0 0 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6em;
  line-height: 1em;
  color: ${p => p.theme.bgHeader};
  mix-blend-mode: normal;
  opacity: 0.8;
`;

const StepText = styled.div`
  padding-bottom: 0.5em;
`;

const Wrapper = styled.div`
  display: flex;
  font-size: 1.5rem;
  align-items: center;
`;

const Step = ({ iconType, title, description }) => (
  <Wrapper>
    <StepIcon type={iconType} />
    <StepText>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </StepText>
  </Wrapper>
);

Step.propTypes = {
  iconType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Step;
