import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Tabs from '../Tabs';

import Icon from '../Icon';
import jsonData from '../../content/vocab-builder/vocab-intro';

const tabs = jsonData.functionalists.reduce((acc, val) => {
  acc.push({ name: val.name, contents: val.paragraphs });
  return acc;
}, []);

const ImgContainer = styled.div`
  width: 23em;
`;

const Title = styled.p`
  font-size: 2.7em;
  line-height: 1.5em;
  text-align: center;
  color: #000000;
  max-width: 13em;
`;

const MainContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 1;
  max-width: 730px;
`;

const TabContainer = styled.div`
  width: 100%;
  margin-bottom: 6em;
`;

const OverflowContainer = styled.div`
  position: relative;
  bottom: -6em;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileTitle = styled(Title)``;

const FlatFunctionalContainer = styled.div`
  margin-left: 2em;
  display: none;
`;
const Functional = styled.div``;
const FunctionalTitleContainer = styled.div`
  font-size: 1em;
  line-height: 2em;
  display: flex;
  align-items: baseline;
`;
const FunctionalTitle = styled.p`
  text-transform: capitalize;
  font-size: 1em;
  margin-left: 1em;
  font-weight: bold;
  margin-bottom: 0.325em;
`;
const FunctionalDescription = styled.p``;

const Wrapper = styled.div`
  position: relative;
  overflow: visible;

  ${MobileTitle} {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    color: white;
    font-size: 0.7rem;
    background-color: ${p => p.theme.bgHeader};
    padding-top: 2em;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    ${MobileTitle} {
      display: block;
      color: white;
      max-width: 80%;
    }

    ${MainContent} {
      display: none;
    }

    ${OverflowContainer} {
      left: -2em;
      bottom: auto;
      align-items: center;
      justify-content: flex-start;
      padding-right: 5%;

      ${ImgContainer} {
        flex: 1;
        max-width: 50vw;
      }

      ${FlatFunctionalContainer} {
        display: block;
        flex: 1;
      }
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 0.7rem;

    ${OverflowContainer} {
      padding-right: 0;

      ${ImgContainer} {
        width: 60em;
      }
    }
  }

  @media screen and (max-width: 425px) {
    ${ImgContainer} {
      max-width: 60vw;
    }

    ${FunctionalDescription} {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* number of lines to show */
    }
  }
`;

const VocabIntro = () => (
  <StaticQuery
    query={graphql`
      query {
        vocabIntro: file(relativePath: { eq: "vocab-builder/vocab-intro.png" }) {
          childImageSharp {
            fluid(maxWidth: 324, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <MobileTitle>{jsonData.title}</MobileTitle>
        <OverflowContainer>
          <ImgContainer>
            <Img fluid={data.vocabIntro.childImageSharp.fluid} />
          </ImgContainer>
          <FlatFunctionalContainer>
            {jsonData.functionalists.map((functionality, id) => (
              <Functional key={id.toString()}>
                <FunctionalTitleContainer>
                  <Icon type={functionality.iconType} />
                  <FunctionalTitle>{functionality.name}</FunctionalTitle>
                </FunctionalTitleContainer>
                <FunctionalDescription>{functionality.paragraphs[0]}</FunctionalDescription>
              </Functional>
            ))}
          </FlatFunctionalContainer>
          <MainContent>
            <Title>{jsonData.title}</Title>
            <TabContainer>
              <Tabs tabs={tabs} />
            </TabContainer>
          </MainContent>
        </OverflowContainer>
      </Wrapper>
    )}
  />
);

export default VocabIntro;
