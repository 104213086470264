import React from 'react';

import { MainLayout } from '../components/Layout';
import SEO from '../components/SEO';
import Container from '../components/Container';

import { Hero, AITech, VocabIntro, Learn } from '../components/VocabBuilder';

const VocabBuilder = () => (
  <MainLayout>
    <SEO title="Home" keywords={[`dolonline`, `dolenglish`, `ielts`, `vocab builder`]} />
    <Container>
      <Hero />
    </Container>
    <Container bgc="#F9F9F9">
      <AITech />
    </Container>
    <Container>
      <VocabIntro />
    </Container>
    <Container>
      <Learn />
    </Container>
  </MainLayout>
);

export default VocabBuilder;
