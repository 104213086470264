import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { media } from '../../utils/MediaResponsive';
import BackgroundImg from '../../images/letters-bgi.png';
import RectangularButton from '../Button/RectangularButton';
import Spacer from '../Spacer';
import jsonData from '../../content/vocab-builder/hero';

const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 70px;
  text-align: center;
  color: ${p => p.theme.bgHeader};

  ${media.tablet`
    font-size: 24px;
    line-height: 40px;
  `}
`;

const Wrapper = styled.div`
  background: url(${BackgroundImg}) center center no-repeat;
  background-size: contain;
`;

const Button = styled(RectangularButton)`
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.0696048);
  border-radius: 4px;
  padding: 15px 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.466667px;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const Hero = () => (
  <Wrapper>
    <ContentContainer>
      <Spacer tablet={100} desktop={200} />
      <Title>
        <b>LEARN</b> NEW WORD <b>SMARTER</b>
      </Title>
      <Spacer height={31} />
      <Button onClick={() => navigate(jsonData.signUp.href)}>{jsonData.signUp.text}</Button>
      <Spacer tablet={100} desktop={200} />
    </ContentContainer>
  </Wrapper>
);

export default Hero;
