import React from 'react';
import styled from 'styled-components';
import { Player, BigPlayButton } from 'video-react';

import Spacer from '../Spacer';
import Step from './Step';
import jsonData from '../../content/vocab-builder/ai-tech';
import vocabVideo from '../../videos/vocab-builder.mp4';
import patternBgi from '../../images/pattern-bgi.png';
import { DesktopAndUp, TabletAndDown } from '../Breakpoint';

const Title = styled.p`
  font-size: 1.5em;
  line-height: 3em;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #d22222;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 2.8em;
  line-height: 1.8em;
  text-align: center;
  color: #000000;
  max-width: 19em;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VideoContainer = styled.div`
  width: 50vw;
`;

const Steps = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 40%;
  margin-left: 4em;
`;

const Wrapper = styled.div`
  padding: 0 5%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background: url(${patternBgi}) right top 50% no-repeat;

  p {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.6rem;

    ${VideoContainer} {
      width: 100vw;
      margin-bottom: 3em;
    }

    ${MainContent} {
      flex-flow: column nowrap;
    }

    ${Steps} {
      margin: 0;
      max-width: unset;
      padding: 0 2em;
    }
  }
`;

const AITech = () => (
  <Wrapper>
    <Spacer desktop={92} />
    <Title>{jsonData.title}</Title>
    <Spacer desktop={12} />
    <Description>
      <DesktopAndUp>{jsonData.description}</DesktopAndUp>
      <TabletAndDown>{jsonData.mobileDescription}</TabletAndDown>
    </Description>

    <Spacer desktop={77} mobile={30} />
    <MainContent>
      <VideoContainer>
        <Player src={vocabVideo}>
          <BigPlayButton position="center" />
        </Player>
      </VideoContainer>
      <Steps>
        {jsonData &&
          jsonData.steps.map(step => (
            <React.Fragment key={step.id}>
              <Step title={step.title} description={step.description} iconType={step.iconType} />
              <Spacer desktop={30} />
            </React.Fragment>
          ))}
      </Steps>
    </MainContent>
    <Spacer desktop={85} />
  </Wrapper>
);

export default AITech;
